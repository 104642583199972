import { Application } from '@hotwired/stimulus'
import Sortable from '@stimulus-components/sortable'
import { BulkEntryItemsFormController } from '@/src/stimulus/controllers/BulkEntryItemsFormController'
import { DirectUploadFormController } from '@/src/stimulus/controllers/DirectUploadFormController'
import { ModalController } from '@/src/stimulus/controllers/ModalController'
import { ScheduleMenuTriggerController } from '@/src/stimulus/controllers/ScheduleMenuTriggerController'
import { TranscriptionAssistantController } from '@/src/stimulus/controllers/TranscriptionAssistantController'
import { TranscriptionFormController } from '@/src/stimulus/controllers/TranscriptionFormController'

const application = Application.start()

application.register('sortable', Sortable)
application.register('bulk-entry-items-form', BulkEntryItemsFormController)
application.register('direct-upload-form', DirectUploadFormController)
application.register('modal', ModalController)
application.register('schedule-menu-trigger', ScheduleMenuTriggerController)
application.register('transcription-assistant', TranscriptionAssistantController)
application.register('transcription-form', TranscriptionFormController)

// application.debug = true
