import { Controller } from '@hotwired/stimulus'
import { DirectUpload } from '@rails/activestorage'

const directUpload = (file: File, directUploadUrl: string): Promise<string> => {
  const upload = new DirectUpload(file, directUploadUrl)
  return new Promise((resolve, reject) => {
    upload.create((error, blob) => {
      if (error) {
        reject(error)
      } else {
        resolve(blob.signed_id)
      }
    })
  })
}

export class DirectUploadFormController extends Controller<HTMLFormElement> {
  static targets = ['input', 'submitter']

  declare readonly submitterTarget: HTMLButtonElement

  inputTargetConnected = (element: HTMLInputElement) => {
    const directUploadUrl = element.dataset.directUploadUrl
    if (!directUploadUrl) return

    element.addEventListener('change', async () => {
      // eslint-disable-next-line unicorn/prefer-spread
      for (const file of Array.from(element.files ?? [])) {
        await directUpload(file, directUploadUrl)
      }
      this.element.requestSubmit(this.submitterTarget)
    })
  }
}
