import { Controller } from '@hotwired/stimulus'
import { Modal } from 'bootstrap'

export class ModalController extends Controller {
  declare modal: Modal

  connect = () => {
    this.modal = new Modal(this.element)
    this.modal.show()
  }

  close = (event: CustomEvent) => {
    if (event.detail.success) {
      this.modal.hide()
    }
  }
}
