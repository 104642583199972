import 'bootstrap'
import 'iconify-icon'
import '@sonicgarden/auto-submit-form-element'
import '@sonicgarden/autosize-textarea-element'
import '@sonicgarden/bs-hovercard-element'
import '@sonicgarden/bs-tooltip-element'
import '@/src/elements/workaround-clickable'
import '@/src/stimulus'
// @ts-expect-error
import { Turbo } from '@hotwired/turbo-rails'
import { initDisableWheelInNumberField } from '@/src/lib/disableWheelInNumberField'
import { displayFlashMessage } from '@/src/lib/displayFlashMessage'
import { initHandsontable } from '@/src/lib/handsontable'
import { initRails } from '@/src/lib/rails'
import { initReact } from '@/src/lib/react'

Turbo.session.drive = false

initHandsontable()
initRails()
initReact()
initDisableWheelInNumberField()

document.addEventListener('DOMContentLoaded', displayFlashMessage)
console.debug('2023-11-24.2') // app/frontend 以下に変更がない場合に tailwindcss の再ビルドが走らない場合があるので、その際はここを更新する
