import { Controller } from '@hotwired/stimulus'
// @ts-expect-error
import { Turbo } from '@hotwired/turbo-rails'
import { projectPath, projectScheduleMenuPath } from '@/src/generated/routes'

const paramsFromTriggerElement = (element?: HTMLElement) => {
  // TODO: stimulus の values を使用して置き換えたい
  const baseDate = element?.closest('table')?.dataset['baseDate']
  const projectId = element?.closest('tr')?.dataset['projectId']
  if (!baseDate || !projectId) {
    return
  }

  return {
    baseDate,
    projectId,
  }
}

export class ScheduleMenuTriggerController extends Controller {
  declare dateValue: string
  declare readonlyValue: boolean

  static values = {
    date: String,
    readonly: Boolean,
  }

  click = () => {
    const params = paramsFromTriggerElement(this.element as HTMLElement)
    if (!params) return

    if (this.readonlyValue) {
      Turbo.visit(projectPath(params.projectId), { frame: '_top' })
    } else {
      const url = projectScheduleMenuPath(params.projectId, { base_date: params.baseDate, date: this.dateValue })
      Turbo.visit(url, { frame: 'modal' })
    }
  }
}
