import RailsNestedForm from '@stimulus-components/rails-nested-form'
import { isEmpty } from '@/src/lib/stringUtils'

const sleep = (time: number) => new Promise((resolve) => setTimeout(resolve, time))

export class BulkEntryItemsFormController extends RailsNestedForm {
  declare contentTargets: readonly HTMLInputElement[]

  static targets = ['content']

  paste = async (e: Event) => {
    e.preventDefault()

    const clipboardText = await navigator.clipboard.readText()
    const clipboardLines = clipboardText.split('\n')

    for (const line of clipboardLines) {
      if (isEmpty(line)) continue

      await sleep(10)
      const event = new CustomEvent('rails-nested-form:paste', { bubbles: true })
      this.add(event)
      this.contentTargets.at(-1)!.value = line.trim()
    }
  }
}
