import { debounce } from '@github/mini-throttle'
import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'
// eslint-disable-next-line import/no-named-as-default
import Sortable from 'sortablejs'

export class TranscriptionAssistantController extends Controller {
  static targets = [
    'newForm',
    'editForm',
    // 追加された要素へのスクロールに使用
    'statement',
    // for Sortable
    'statementList',
  ]

  declare readonly newFormTarget: HTMLFormElement
  declare readonly editFormTargets: readonly HTMLFormElement[]
  declare readonly statementListTarget: HTMLDivElement
  declare isAutoScrollEnabled: boolean
  declare sortable: Sortable

  connect = (): void => {
    // NOTE: 画面表示のタイミングでは書き起こしの要素への自動スクロールをしたくないので時間差で有効にしている
    this.setAutoScrollEnabled(false)
    setTimeout(() => {
      this.setAutoScrollEnabled(true)
    }, 1000)

    this.sortable = new Sortable(this.statementListTarget, {
      animation: 300,
      filter: 'textarea',
      preventOnFilter: false,
      onStart: () => this.setAutoScrollEnabled(false),
      onEnd: () => this.setAutoScrollEnabled(true),
      onUpdate: this.updateStatementPosition,
    })

    this.element.addEventListener('click', this.autoCloseForm, { capture: true }) // `useCapture: true` としないと react-select のクリックを正しく判定できない場合がある
  }

  disconnect = (): void => {
    this.sortable.destroy()
  }

  statementTargetConnected = (element: HTMLElement): void => {
    if (!this.isAutoScrollEnabled) return
    if (this.editFormTargets.length > 0) return

    this.debouncedScrollToElement(element)
  }

  autoCloseForm = (event: Event) => {
    const { target } = event
    if (!(target instanceof HTMLElement)) return
    if (target.closest('button')) return
    if (target.closest('div[id^="react-select-"]')) return

    const form = target.closest('form')
    for (const editForm of this.editFormTargets) {
      if (editForm !== form) {
        // 現在アクティブなフォーム以外は閉じる対応
        // とくにバリデーションが必要な箇所ではないため、「閉じる = 保存」のためサブミットしている
        editForm.requestSubmit()
      }
    }
  }

  currentForm = (): HTMLFormElement => {
    return this.editFormTargets[0] || this.newFormTarget
  }

  // 質問と発言の紐づけ(紐づけのみ)
  associateToStatement = (event: Event, overwriteContent: boolean = false): void => {
    const { interviewItemGid } = (event.currentTarget as HTMLButtonElement).dataset
    if (!interviewItemGid) {
      console.debug('interviewItemGid is undefined.', event.currentTarget)
      return
    }

    const form = this.currentForm()
    const container = form?.querySelector<HTMLDivElement>('.js-interview-item-gid-container')
    const submitter = form?.querySelector<HTMLInputElement>('input[name="refresh"]')
    if (!container || !submitter) return

    while (container.firstChild) {
      container.firstChild.remove()
    }
    container.append(this.createHiddenTag('interview_transcription_statement[interview_item_gids][]', interviewItemGid))
    if (overwriteContent) {
      container.append(this.createHiddenTag('overwrite_content', '1'))
    }
    form.requestSubmit(submitter)
  }

  // 質問と発言の紐づけ(入力内容を上書きする)
  associateAndCopyToStatement = (event: Event): void => {
    const form = this.currentForm()
    const textarea = form.querySelector<HTMLTextAreaElement>('textarea')
    if (!textarea) return

    if (textarea.value.length === 0 || window.confirm('入力中の内容は上書きされます。よろしいですか？')) {
      this.associateToStatement(event, true)
    }
  }

  private createHiddenTag = (name: string, value: string): HTMLInputElement => {
    const hiddenTag = document.createElement('input')
    hiddenTag.type = 'hidden'
    hiddenTag.name = name
    hiddenTag.value = value
    return hiddenTag
  }

  private setAutoScrollEnabled = (enabled: boolean): void => {
    console.debug('auto scroll enabled:', enabled)
    this.isAutoScrollEnabled = enabled
  }

  private scrollToElement = (element: HTMLElement): void => {
    console.debug('scroll to', element)
    element.scrollIntoView({ block: 'nearest' })
  }
  private debouncedScrollToElement = debounce(this.scrollToElement, 200)

  private updateStatementPosition({ item, newIndex }: { item: HTMLElement; newIndex?: number }): void {
    const url = item.dataset.moveUrl
    const token = Rails.csrfToken()
    if (!url || !token || newIndex === undefined) return

    console.debug('move', { target: item, position: newIndex + 1 })

    fetch(url, {
      method: 'PATCH',
      body: JSON.stringify({ position: newIndex + 1 }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token,
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
  }
}
